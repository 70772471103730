import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';

import { Callback } from '../../../types';
import Axios from 'axios';

export default function useHandleRoomDisconnectionErrors(room: Room, onError: Callback) {
  useEffect(() => {
    const onDisconnected = (room: Room, error: TwilioError) => {
      if (error) {
        onError(error);
        Axios({
          method: 'post',
          url: 'https://woundreference.com/api/twiliolog.ashx',
          params: {
            code: error.code,
            description: error.message,
            room: room.name
          }
        }).then((res)=>{
          console.log(res);
        })
          .catch((err)=>{
            console.log(err);
          })
      }
    };

    room.on('disconnected', onDisconnected);
    return () => {
      room.off('disconnected', onDisconnected);
    };
  }, [room, onError]);
}
