import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import axios from 'axios';
import '../Controls/controls.css';
import Tooltip from '@material-ui/core/Tooltip';

export default function SnapShotButton() {

  const [dataurl, setUrl] = useState('');


    const onClick = async function () {
        let videoLength = document.getElementsByTagName('video').length;
        let video = document.getElementsByTagName('video')[videoLength - 1];
        let canvas = document.getElementById('canvas') as HTMLCanvasElement;
        let w = video.videoWidth;
        let h = video.videoHeight;
        canvas.width = w;
        canvas.height = h;
        let ctx = canvas.getContext('2d');
    
        if (ctx) {
    
          ctx.drawImage(video, 0, 0);
        }
        // video.style.backgroundImage = "url(" + canvas.toDataURL() + ")";
        video.style.backgroundRepeat = "no-repeat";
    
        let dataUrl_ = canvas.toDataURL("image/jpeg");
        setUrl(dataUrl_);
    
        var modal: HTMLInputElement = document.getElementById("myModal") as HTMLInputElement;
        modal.style.display = "block";
        var span = document.getElementsByClassName("cancel_btn")[0] as HTMLInputElement;
        span.onclick = function () {
          modal.style.display = "none";
          video.style.backgroundImage = "url(" + "" + ")"
        }
    }

    let btn = document.getElementById("post_btn") as HTMLInputElement;
    if(btn) {

        btn.onclick = function() {
    
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let URLRoomName = params.get('room');
        
            var bodyFormData = new FormData();
            bodyFormData.set('roomName', URLRoomName!);
            bodyFormData.append('image', dataurl);
            bodyFormData.set('StatusCallbackEvent', 'image-captured');
        
            axios({
              method: 'post',
              url: 'https://woundreference.com/twiliocallback',
              data: bodyFormData,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((res) => {
              console.log(res);
              var modal: HTMLInputElement = document.getElementById("myModal") as HTMLInputElement;
              modal.style.display = "none";
              // let canvas = document.getElementById('canvas') as HTMLCanvasElement;
              // canvas.style.display = "none";
              let videoLength = document.getElementsByTagName('video').length;
              let video = document.getElementsByTagName('video')[videoLength - 1];
              video.style.backgroundImage = "url(" + "" + ")"
            })
              .catch((err) => {
                console.log(err)
              })
        }
    }

    return (
        <>
        <Tooltip title="Screen Capture" placement="bottom">
            <IconButton onClick={onClick}>
                <PhotoCamera />
            </IconButton>
        </Tooltip>
        </>
    )
}