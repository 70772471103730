import React, { FormEvent, ChangeEvent, useState, useEffect } from "react";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import CircularProgress from '@material-ui/core/CircularProgress';

import EndCallButton from "./EndCallButton/EndCallButton";
import ToggleAudioButton from "./ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "./ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "./ToogleScreenShareButton/ToggleScreenShareButton";
import useIsUserActive from "./useIsUserActive/useIsUserActive";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useAppState } from '../../state';
import { isMobile } from '../../utils';

import './controls.css';
import LocalAudioLevelIndicator from "../MenuBar/DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    container: {
      display: "flex",
      position: "absolute",
      right: "50%",
      transform: "translate(50%, 30px)",
      bottom: "50px",
      zIndex: 1,
      transition: "opacity 1.2s, transform 1.2s, visibility 0s 1.2s",
      opacity: 0,
      visibility: "hidden",
      maxWidth: "min-content",
      "&.showControls, &:hover": {
        transition: "opacity 0.6s, transform 0.6s, visibility 0s",
        opacity: 1,
        visibility: "visible",
        transform: "translate(50%, 0px)"
      },
      [theme.breakpoints.down("xs")]: {
        bottom: `${theme.sidebarMobileHeight + 3}px`
      }
    },
    loadingSpinner: {
      marginLeft: '2.5rem',
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiInput-underline': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);


export default function Controls() {
  const classes = useStyles();
  const roomState = useRoomState();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect } = useVideoContext();

  const isReconnecting = roomState === "reconnecting";
  const isUserActive = useIsUserActive();
  const showControls = isUserActive || roomState === "disconnected";
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [modal, setShowModal] = useState(false);
  const [visible, setVisible] = useState<boolean>(true);


  let search = window.location.search;
  let params = new URLSearchParams(search);
  let URLRoomName = params.get('room');
  let IdentifierName = params.get('name');

  useEffect(() => {
    if(roomState === 'disconnected') {
      setShowModal(false);
    }
    setOpen(true);
    if (URLRoomName) {
      console.log(URLRoomName);
      setRoomName(URLRoomName);
    }

    if (IdentifierName) {
      setName(IdentifierName);
    }

    if (URLRoomName && IdentifierName && visible && roomState === 'disconnected') {

      setVisible(false);
    }
  }, [URLRoomName, IdentifierName, roomState, visible]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    setShowModal(true);
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`${window.location.search || ''}`));
    }
    getToken(name, roomName).then(token => connect(token));
  };

  const onRefresh = () => {
    window.location.reload();
  }

  return (
    <>
      <div id="myModal" className="modal">
        <div>

        </div>

        <div id="modal_content" className="modal-content">
          <div className="div_above_canvas">

          <div id="canvas_div">
          <span style={{ margin: '3px' }}><button id="post_btn" className="btn post_btn">Save Image</button></span>
          <span style={{ margin: '3px' }}><button className="btn cancel_btn">Cancel</button></span>
            <div style={{ color: 'black', fontSize: '14px', marginLeft: '120px', display: 'inline' }} id="show_text"></div>
            <canvas id="canvas" style={{ width: '100%', marginTop: '12px' }} ></canvas>
          </div>
          </div>
        </div>

      </div>

      <div style={{ zIndex: 1400 }} id="media_error_block" className="media_error_modal">
        <div style={{ height: 'auto', maxHeight: '500px', overflow: 'auto' }} id="media_error_block_content" className="modal-content">
          <div id="media_error_name" style={{ height: '40px', marginTop: '-25px', marginBottom: '15px', display: 'contents' }} className="div_err_name">
          </div>
          <div id="media_error_desc"></div>
          <p>Please correct error and rejoin room.</p>
          <span style={{ marginTop: '-17px', float: 'right' }}><button id="err_close_btn" style={{ backgroundColor: '#92cf5c' }} className="btn cancel_btn">Cancel</button></span>
        </div>
      </div>

      <div>
      <Dialog style={{display: modal ? 'none' : 'block' }} open={open} aria-labelledby="form-dialog-title">
        <DialogTitle style={{ color: 'white' }} id="form-dialog-title">START TELEVISIT...</DialogTitle>
        <div style={{ display: 'flex', marginTop: '15px', padding: '2px 15px' }}>
          <LocalAudioLevelIndicator />          
          <div className="mic_level_txt">Wait a few seconds and say "Hello". If the microphone on the left stays black, please <a onClick={onRefresh} style={{ color: 'deepskyblue' }} href="">refresh</a> browser. If you see the green color moving, 
          { visible ? ' Click "JOIN ROOM" and your provider will join shortly.' : ' Click "JOIN ROOM" to start video call'} </div>
        </div>
        <form style={{ marginTop: '-20px' }} onSubmit={handleSubmit}>
        <DialogContent>
              <CssTextField 
                style={{ display: (visible) ? "block" : "none" }}
                margin="dense"
                className={classes.margin} 
                id="name" 
                type="text"
                value={roomName}
                onChange={handleRoomNameChange}
                fullWidth
                label="Room name" />
              <CssTextField 
                style={{ display: (visible) ? "block" : "none" }}
                margin="dense"
                className={classes.margin} 
                id="username" 
                type="text"
                value={name}
                onChange={handleNameChange}
                fullWidth
                label="User name" />
        </DialogContent>
        
        <DialogActions>
          <Button disabled={isConnecting || !name || !roomName || isFetching} type="submit" className="start_call_btn"  color="primary">
            Join Room
          </Button>
          {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
        </DialogActions>
        </form>        
      </Dialog>
    </div>
    
      <div style={{ display: show ? 'flex' : 'none' }} className={clsx(classes.container, { showControls })}>
        <ToggleAudioButton disabled={isReconnecting} />
        <ToggleVideoButton disabled={isReconnecting} />
        {roomState !== "disconnected" && (
          <>
            { !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
            <EndCallButton />
          </>
        )}
      </div>
    </>
  );
}