import {
    IconButton
} from '@material-ui/core';
// import PhotoCamera from '@material-ui/icons/PhotoCamera';
// import Axios from 'axios';
import '../Controls/controls.css';
import Tooltip from '@material-ui/core/Tooltip';
import Axios from 'axios';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import React, {
    useState,
    useEffect
} from 'react';
import {
    Cached
} from '@material-ui/icons';

export default function ResetButton() {

    // const [dataurl, setUrl] = useState('');

    // const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [isHost, setIsHost] = useState("");
    const {
        room
    } = useVideoContext();

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        // let URLRoomName = params.get('room');
        let host = params.get('host');
        if (host) {
            setIsHost(host);
        }
    }, [])


    const resetTracks = () => {
        // room.disconnect();
        console.log(room);
        Axios({
                method: 'post',
                url: 'https://woundreference.com/api/RoomComplete.ashx',
                params: {
                    sid: room.sid
                }
            }).then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    }


return (
<>
    <Tooltip title="Restart call to reset audio & video" placement="bottom">
        <IconButton onClick={resetTracks}>
            <Cached />
        </IconButton>
    </Tooltip>
</>
)
}