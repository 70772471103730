import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import { DeviceSelector } from './DeviceSelector/DeviceSelector';
import SnapShotButton from './SnapShotButton';
import './MenuBar.css';
import { isMobile } from '../../utils';
import ToggleThumbnailButton from './ToggleThumbnailButton/ToggleThumbnailButton';
import LocalAudioLevelIndicator from './DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import ResetButton from './ResetButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    joinButton: {
      margin: '1em',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isFetching } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();
  const [isHost, setIsHost] = useState("");


  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let host = params.get('host');
    if(host) {
      setIsHost(host);
    }
  })

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
        <div className={classes.rightButtonContainer}>
          { isMobile && <FlipCameraButton />}
          { (roomState === 'connected' && isHost ) && <ResetButton />}
          { 
            roomState === 'disconnected' ? (
              ''
            ) : (
              <>
              { isHost && <SnapShotButton />}
              {/* { isMobile && <FlashLightButton /> } */}
              </>
            )
          }
          { roomState !== 'disconnected' && <DeviceSelector />}
          { roomState !== 'disconnected' && <ToggleThumbnailButton />}
          { !isMobile && <ToggleFullscreenButton />}
          <LocalAudioLevelIndicator />
          <Menu />
        </div>
      </Toolbar>
    </AppBar>
  );
}