import React from "react";
import Participant from "../Participant/Participant";
import { styled } from "@material-ui/core/styles";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";

const Container = styled("aside")(({ theme }) => ({
	padding: "0.5em",
	overflowY: "auto",
	[theme.breakpoints.down("xs")]: {
		overflowY: "initial",
		overflowX: "auto",
		padding: 0,
		display: "flex",
	},
	position: "absolute",
	top: 0,
	left: 0,
	zIndex: 5,
}));

const ScrollContainer = styled("div")(({ theme }) => ({
	[theme.breakpoints.down("xs")]: {
		display: "flex",
	},
	maxWidth: "250px",
}));

export default function ParticipantStrip() {
	
	const {
		room: { localParticipant },
	} = useVideoContext();
	const participants = useParticipants();

	const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

	return (
		<Container id="toggle_participants_thumbnails">
			<ScrollContainer>
				<Participant
					participant={localParticipant}
					isSelected={selectedParticipant === localParticipant}
					onClick={() => setSelectedParticipant(localParticipant)}
				/>
				{participants.map((participant) => (
					<Participant
						key={participant.sid}
						participant={participant}
						isSelected={selectedParticipant === participant}
						onClick={() => setSelectedParticipant(participant)}
					/>
				))}
			</ScrollContainer>
		</Container>
	);
}
