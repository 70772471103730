import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useParticipants() {
  const { room } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState(Array.from(room.participants.values()));
  const {
		room: { localParticipant },
	} = useVideoContext();
	// const participants = useParticipants();

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants(prevParticipants => [
        dominantSpeaker,
        ...prevParticipants.filter(participant => participant !== dominantSpeaker),
      ]);
    }
  }, [dominantSpeaker]);

  function checkParticipants() {
		console.log(room.participants.size);
		console.log(room.participants.size == 0);
		if(room.participants.size == 0) {
		console.log("number of participants",room.participants.size);

			disconnectRoom();
		}
  }
  
  function disconnectRoom() {
		setTimeout(() => {
			room.disconnect();
		}, 10 * 60 * 1000);
	}

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
      checkParticipants();
    }
    const participantDisconnected = (participant: RemoteParticipant) => {
      setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
      checkParticipants();
    }
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  return participants;
}
