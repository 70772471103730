import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import People from '@material-ui/icons/People';
import Tooltip from '@material-ui/core/Tooltip';

export default function ToggleThumbnailButton() {

    let [thumbnailShow, setThumbnail] = useState(false);

    const toggleThumbnail = async function() {
        await setThumbnail(!thumbnailShow);
        console.log(thumbnailShow);
        // alert('toggle thumbnails')
        let thumbnail_container = document.getElementById('toggle_participants_thumbnails');
        if(thumbnail_container) {
            if (thumbnailShow === true) {
                thumbnail_container.style.display = 'flex';
    
                // document.getElementById(ele.id).value = 'Show DIV';
        }
            else {
                thumbnail_container.style.display = 'none';
                // document.getElementById(ele.id).value = 'Hide DIV';
            }

        }
        console.log(thumbnail_container)
    }

    return(
        <Tooltip title="Toggle Participant Thumbnail" placement="bottom">
            <IconButton className="toggle_thumbnail_switch" onClick={toggleThumbnail}>
                <People />
            </IconButton>
        </Tooltip>
    )
}