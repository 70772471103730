import { useCallback, useEffect, useState } from 'react';

import { ensureMediaPermissions } from '../../../utils';
import Video, { LocalVideoTrack, LocalAudioTrack, CreateLocalTrackOptions } from 'twilio-video';
import Axios from 'axios';
let URLRoomName: any;

const mediaErrors = [
  'NotAllowedError',
  'NotFoundError',
  'NotReadableError',
  'OverconstrainedError',
  'TypeError'
];

function handleMediaError(err: any) {  
  var modal: HTMLInputElement = document.getElementById("media_error_block") as HTMLInputElement;
  if(modal){
    modal.style.display = "block"
  }
  var span = document.getElementById("err_close_btn") as HTMLInputElement;
  if(span) {
    span.onclick = function () {
      modal.style.display = "none";
      // room.disconnect();
      // window.location.reload();
    }
  }
  let error_content = document.getElementById("media_error_desc");
  let error_name = document.getElementById("media_error_name");
  if(error_name) {
    error_name.innerHTML = "<h2>" + err.name + ": " + err.message + "</h2>";
  }
  if(error_content) {
    if(err.name === 'NotAllowedError') {
      error_content.innerHTML = "<ol><li>Check your browser settings to make sure you've enabled your browser to access your camera and microphone. (see <a href='https://woundreference.zendesk.com/hc/en-us/articles/360047213651-Troubleshooting-a-video-visit-for-Clinicians-#h_355eda1b-4dfb-407f-ab79-39cf8e8fa108'>support article </a>)</li> <li>Reload/refresh this page and click on 'Allow' when asked to grant permission to access the microphone and camera.</li></ol>"
    }
    if(err.name === 'NotFoundError') {
      error_content.innerHTML = "<ol><li>Check your browser settings to make sure you've enabled your browser to access your camera and microphone. (see <a href='https://woundreference.zendesk.com/hc/en-us/articles/360047213651-Troubleshooting-a-video-visit-for-Clinicians-#h_355eda1b-4dfb-407f-ab79-39cf8e8fa108'>support article </a>)</li> <li>Check if you have a working camera and microphone in your desktop, laptop, tablet or mobile.</li></ol>"
    }
    if(err.name === 'NotReadableError') {
      error_content.innerHTML = "Another app or tab is currently using your device's camera and/or microphone and is interfering with this televisit. Please close all other apps and tabs that might be using your device's camera and/or microphone and reload this page. If this doesn't work, close your internet browser (e.g. Chrome, Safari, Firefox etc), then start the televisit again by clicking on the link you received."
    }
    if(err.name === 'OverconstrainedError') {
      error_content.innerHTML = "During the televisit, click on the Cog icon in the top menu, and ensure Audio Input/Output and Video Input are using your device's default microphone and camera."
    }
    if(err.name === 'TypeError') {
      error_content.innerHTML = "Your app should be served from a secure context (localhost or https)."
    }
  }
  if(err) {
    console.log(err.name);
    Axios({
      method: 'post',
      url: 'https://woundreference.com/api/twiliolog.ashx',
      params: {
        code: err.name,
        description: err.message,
        room: URLRoomName
      }
    }).then((res)=>{
      console.log(res);
    })
      .catch((err)=>{
        console.log(err);
      })
  }
}

export function useLocalAudioTrack() {
  const [track, setTrack] = useState<LocalAudioTrack>();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  URLRoomName = params.get('room');

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return ensureMediaPermissions().then(() =>
      Video.createLocalAudioTrack(options).then(newTrack => {
        setTrack(newTrack);
        return newTrack;
      }).catch(err=>{
        if(mediaErrors.includes(err.name)) {
          handleMediaError(err);         
        }
        return err;
      })
    );
  }, []);

  useEffect(() => {
    getLocalAudioTrack();
  }, [getLocalAudioTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalAudioTrack] as const;
}

export function useLocalVideoTrack() {
  const [track, setTrack] = useState<LocalVideoTrack>();

  const getLocalVideoTrack = useCallback((newOptions?: CreateLocalTrackOptions) => {
    // In the DeviceSelector and FlipCameraButton components, a new video track is created,
    // then the old track is unpublished and the new track is published. Unpublishing the old
    // track and publishing the new track at the same time sometimes causes a conflict when the
    // track name is 'camera', so here we append a timestamp to the track name to avoid the
    // conflict.
    const options: CreateLocalTrackOptions = {
      frameRate: 24,
      height: 720,
      width: 1280,
      facingMode: 'user',
      name: `camera-${Date.now()}`,
      ...newOptions,
    };

    return ensureMediaPermissions().then(() =>
      Video.createLocalVideoTrack(options).then(newTrack => {
        setTrack(newTrack);
        return newTrack;
      }).catch(error=>{
        if(mediaErrors.includes(error.name)) {
          handleMediaError(error);
        }
        return error;
      })
    );
  }, []);

  useEffect(() => {
    // We get a new local video track when the app loads.
    getLocalVideoTrack();
  }, [getLocalVideoTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalVideoTrack] as const;
}

export default function useLocalTracks() {
  const [audioTrack, getLocalAudioTrack] = useLocalAudioTrack();
  const [videoTrack, getLocalVideoTrack] = useLocalVideoTrack();

  const localTracks = [audioTrack, videoTrack].filter(track => track !== undefined) as (
    | LocalAudioTrack
    | LocalVideoTrack
  )[];

  return { localTracks, getLocalVideoTrack, getLocalAudioTrack };
}
