import React, { useState, useEffect } from 'react';

import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import { Dialog, IconButton, DialogContent, Button, Theme } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import VideoInputList from './VideoInputList/VideoInputList';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import Axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '500px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 32px)',
      },
      '& .inputSelect': {
        width: 'calc(100% - 35px)',
      },
    },
    listSection: {
      margin: '0.5em 0',
    },
    button: {
      float: 'right',
      backgroundColor: '#92cf5c'
    },
    paper: {
      [theme.breakpoints.down('xs')]: {
        margin: '16px',
      },
    },
  })
);

export function DeviceSelector() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isHost, setIsHost] = useState("");
  const { room } = useVideoContext();

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    // let URLRoomName = params.get('room');
    let host = params.get('host');
    if(host) {
      setIsHost(host);
    }
  })

  // const resetTracks = () => {
  //     // room.disconnect();
  //     console.log(room);
  //     Axios({
  //       method: 'post',
  //       url: 'https://woundreference.com/api/RoomComplete.ashx',
  //       params: {
  //         sid: room.sid
  //       }
  //     }).then(res=>{
  //       console.log(res);
  //     })
  //       .catch(err=>{
  //         console.log(err);
  //       })
  // }

  return (
    <>
      <Tooltip title="Call Settings" placement="bottom">
        <IconButton style={{ padding: '9px' }} onClick={() => setIsOpen(true)} data-cy-device-select>
          <SettingsIcon />
        </IconButton>
      </Tooltip>      
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} classes={{ paper: classes.paper }}>
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ display: 'inline' }}>CALL SETTINGS</h3>
          <label onClick={() => setIsOpen(false)} style={{ float: 'right' }}><CloseIcon /></label>
        </div>
        {/* { isHost==="true" && 
          <Button style={{ float: 'right', backgroundColor: '#92cf5c', borderRadius: '0', padding: '6px 0', margin: '5px 0' }} onClick={resetTracks}>
            Restart call to reset audio & video
          </Button>
        } */}
        <DialogContent style={{ backgroundColor: 'dimgray', paddingTop: '5px' }} className={classes.container}>
          <div className={classes.listSection}>
            <AudioInputList />
          </div>
          <div className={classes.listSection}>
            <AudioOutputList />
          </div>
          <div className={classes.listSection}>
            <VideoInputList />
          </div>                    
        </DialogContent>
      </Dialog>
    </>
  );
}
